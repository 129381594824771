import React from 'react';
import { Outlet, Link } from "react-router-dom";
import TopMenuAutenticadoTheme1 from '../../Shared/TopMenuAutenticadoTheme1';
export default function Navbar() {
    return (
        <>

            <Outlet />
            <div style={{
                position: "fixed",
                bottom: "0px",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                height: "40px",
                backgroundColor: "#ffffff",
                alignItems: "center",
                padding: "0px 20px"
            }}>
                <Link to="/">dashboard</Link>
                <Link to="/alimentacao">Alimentacao</Link>
                <Link to="/acompanhamento">Acompanhamentos</Link>
                <Link to="/informacao">Informações</Link>
                <Link to="/usuario">meus dados</Link>
            </div>
        </>
    );
}
