import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';


import usuarioService from '../../../../Service/Usuario/usuarioService';
import AcompanhamentoService from '../../../../Service/Usuario/AcompanhamentoService';
import AtividadeService from '../../../../Service/Usuario/AtividadeService';
import DietaService from '../../../../Service/Usuario/DietaService';

//modal
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
//modal
















export default function UsuarioPage() {

  //modal acompanhamento
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const fecharModalAcompanhamento = () => {
    console.log("fechou")
    setOpen(false);
  }
  //modal acompanhamento

  const [usuario, setUsuario] = React.useState({
    nome: "",
    usuario: "",
    data_nascimento: "",
    sexo: "",
    idade: ""
  })
  const [ultimoAcompanhamento, setUltimoAcompanhamento] = React.useState(null);
  const [ultimaAtividade, setUltimaAtividade] = React.useState(null);
  const [dietaRefeicoes, setDietaRefeicoes] = React.useState(null);








  React.useEffect(() => {
    //usuarioService.getUsuarioByToken
    usuarioService.getUsuarioByToken()
      .then((response) => {
        if (response) {
          setUsuario({
            nome: response.nome,
            usuario: response.usuario,
            //data_nascimento: response.data_nascimento.split(" ").split("-").reverse().join("/"),
            data_nascimento: response.data_nascimento,
            sexo: response.sexo == 1 ? "Homem" : "Mulher",
            idade: calcularIdade(response.data_nascimento)
          })
        }
      });
    /*
          InformacaoPessoalService.GetLast()
          .then((response) => {
            if (response.cod == 200) {
              setUltimoAcompanhamento(response.data);
            }
          })
          */
    AcompanhamentoService.GetLast()
      .then((response) => {
        if (response) {
          setUltimoAcompanhamento(response);
        }
      });
    AtividadeService.GetLast().then((response) => {
      if (response) {
        setUltimaAtividade(response);
      }
    });
    DietaService.Todos().then((response) => {
      if (response) {
        setDietaRefeicoes(response);
      }
    });
  }, []);


  //utils
  const calcularIdade = (dataString) => {
    const dataNascimento = new Date(dataString);
    const dataAtual = new Date();
    const diferencaMilissegundos = dataAtual - dataNascimento;
    return Math.floor(diferencaMilissegundos / (365.25 * 24 * 60 * 60 * 1000));
  }









  const [proteina, setProteina] = React.useState(30);
  const [carboidrato, setCarboidrato] = React.useState(50);
  const [gordura, setGordura] = React.useState(20);

  React.useEffect(() => {
    total = proteina + carboidrato + gordura;

    toRadians = angle => angle * (Math.PI / 180);
  }, [proteina, carboidrato, gordura]);

  var total = proteina + carboidrato + gordura;

  var toRadians = angle => angle * (Math.PI / 180);

  const createArc = (startAngle, endAngle, color) => {
    const x1 = 100 + 100 * Math.cos(toRadians(startAngle));
    const y1 = 100 + 100 * Math.sin(toRadians(startAngle));
    const x2 = 100 + 100 * Math.cos(toRadians(endAngle));
    const y2 = 100 + 100 * Math.sin(toRadians(endAngle));

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
      `M 100 100`,
      `L ${x1} ${y1}`,
      `A 100 100 0 ${largeArcFlag} 1 ${x2} ${y2}`,
      `L 100 100`
    ].join(' ');

    return <path d={d} fill={color} />;
  };









  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Adicionar Acompanhamento
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            aaaaaaaaaaaa
          </Typography>
        </Box>
      </Modal>





      <Card style={{ margin: "10px" }}>
        <CardHeader title="Informações Pessoais" />
        <CardContent className='formulario-grupo'>

          <div>
            <ul>
              <li><label>Usuário: </label>{usuario.usuario}</li>
              <li><label>Nome: </label>{usuario.nome}</li>
              <li><label>Data de Nascimento: </label>{usuario.data_nascimento}</li>
              <li><label>Idade: </label>{usuario.idade} anos</li>
              <li><label>Sexo: </label>{usuario.sexo}</li>
            </ul>

            

          </div>

        </CardContent>
      </Card>

    </div>
  );
}

