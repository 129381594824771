// usuarioService.js
import axios from '../axiosConfig';

const usuarioService = {



  
  entrar: async (dadosUsuario) => {

    try {
      const response = await axios.post('/api/usuario/entrar', dadosUsuario);
      return response.data;
    } catch (error) {
      console.error('Erro ao cadastrar usuário:', error);
      throw error;
    }
  },


  getUsuarioByToken: async () => {
    try {
      const response = await axios.post(`/api/usuario/getusebytoken`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter usuário:', error);
      throw error;
    }
  },








  // Exemplo de requisição POST
  cadastrarUsuario: async (dadosUsuario) => {
    try {
      const response = await axios.post('/auth/login', dadosUsuario);
      return response.data;
    } catch (error) {
      console.error('Erro ao cadastrar usuário:', error);
      throw error;
    }
  },


  // Exemplo de requisição PUT
  atualizarUsuario: async (id, novosDados) => {
    try {
      const response = await axios.put(`/auth/login/${id}`, novosDados);
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      throw error;
    }
  },

  // Exemplo de requisição DELETE
  excluirUsuario: async (id) => {
    try {
      const response = await axios.delete(`/auth/login/${id}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao excluir usuário:', error);
      throw error;
    }
  },
};

export default usuarioService;
