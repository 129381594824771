import React from 'react';
import './style.css';

import AlertComponent from './Components/Shared/AlertComponent';
import LoaderComponent from './Components/Shared/LoaderComponent';

import RouterRoot from './Components/RouterRoot';
import {
  RouterProvider,
} from "react-router-dom";

export default function App() {
  return <>
    <LoaderComponent />
    <AlertComponent />
    <RouterProvider router={RouterRoot} /></>
}
