import axios from '../axiosConfig';

const RefAlimentoService = {
    Todos: async () => {
        try {
            const response = await axios.get('/api/ref_alimento');
            return response.data;
        } catch (error) {
            console.error('Erro ao cadastrar usuário:', error);
            throw error;
        }
    },

};

export default RefAlimentoService;
