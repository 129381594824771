import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from "react-router-dom";

//públicas
import LoginPage from "./Pages/NaoAutenticado/LoginPage";

//privadas
import AutenticadoRoot from './Pages/Autenticado/AutenticadoRoot'
import AtendimentoPage from "./Pages/Autenticado/Dashboard/CadastroPage";
import AcompanhamentoPage from "./Pages/Autenticado/Acompanhamento/AcompanhamentoPage";
import AlimentacaoPage from "./Pages/Autenticado/Alimentacao/AlimentacaoPage";
import InformacoesPage from "./Pages/Autenticado/Informacoes/InformacoesPage";
import UsuarioPage from "./Pages/Autenticado/Usuario/UsuarioPage";



let primeiraPagina = "/coi/atendimento";

function rootAction({ request, params }) {
  const isAuthenticated = localStorage.getItem('token') !== null;
  if (request.url.split("/")[3] == "login") {
    if (isAuthenticated) {
      return redirect(primeiraPagina);
    }
  } else {
    if (!isAuthenticated) {
      return redirect(`/login`);
    }
  }
  return request
}



const RouterRoot = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
    loader: rootAction,
  },
  {
    path: "/",
    element: <AutenticadoRoot />,
    loader: rootAction,
    children: [
      //rotas coi
      {
        path: "/",
        element: <AtendimentoPage />,
      },
      {
        path: "/acompanhamento",
        element: <AcompanhamentoPage />,
      },
      {
        path: "/alimentacao",
        element: <AlimentacaoPage />,
      },
      {
        path: "/informacao",
        element: <InformacoesPage />,
      },
      {
        path: "/usuario",
        element: <UsuarioPage />,
      }
    ],
  },
]);

export default RouterRoot