// usuarioService.js
import axios from '../axiosConfig';

const AtividadeService = {
    GetLast: async () => {
        try {
            const response = await axios.get('/api/atividade/lastbytoken');
            return response.data;
        } catch (error) {
            console.error('Erro ao cadastrar usuário:', error);
            throw error;
        }
    },
};

export default AtividadeService;
