import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import DietaService from '../../../../Service/Usuario/DietaService';
import RefeicaoService from '../../../../Service/Usuario/RefeicaoService';
import RefAlimentoService from '../../../../Service/Usuario/RefAlimentoService';
import AlimentoService from '../../../../Service/Usuario/AlimentoService';

//modal
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { json } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
//modal







export default function AlimentacaoPage() {

    const [usuario_dieta_refeicao_alimento, setUsuario_dieta_refeicao_alimento] = React.useState();

    //modal alimento
    const [modalAlimentoOpen, setModalAlimentoOpen] = React.useState(false);
    const abrirModalAlimento = (refeicao) => {
        console.log(refeicao)
        setUsuario_dieta_refeicao_alimento({ ...usuario_dieta_refeicao_alimento, usuario_dieta_refeicao_id: refeicao.id })

        setModalAlimentoOpen(true);
    }
    const fecharModalAlimento = () => { setModalAlimentoOpen(false) }
    const insertAlimento = (event) => {
        event.preventDefault();
        if (document.querySelector("[name='alimento']").selectedOptions[0] != undefined && document.querySelector("[name='alimentoquantidade']").value > 0) {

            let objJson = JSON.parse(document.querySelector("[name='alimento']").selectedOptions[0].dataset.alimentojson);
            let objetoEnvio = {
                usuario_dieta_refeicao_id: usuario_dieta_refeicao_alimento.usuario_dieta_refeicao_id,
                nome: objJson.nome,
                descricao: objJson.origem,
                gramas: objJson.gramas,
                calorias: objJson.calorias,
                carboidratos: objJson.carboidratos,
                proteinas: objJson.proteinas,
                gordura: objJson.gordura,
                quantidade: document.querySelector("[name='alimentoquantidade']").value
            }
            AlimentoService.Insert(objetoEnvio).then((response) => {
                fecharModalAlimento();
                atualizarListaDietas();
            })

        } else {
            alert("Preencha quantidade e selecione alimento")
        }
    }
    const deleteAlimento = (id) => {
        if (confirm("Deseja realmente apagar o alimento?")) {
            AlimentoService.Delete(id).then((response) => {
                atualizarListaDietas();
            });
        }
    }
    //modal alimento

    //modal refeição
    const [modalRefeicaoOpen, setModalRefeicaoOpen] = React.useState(false);
    const abrirModalRefeicao = () => setModalRefeicaoOpen(true);
    const fecharModalRefeicao = () => setModalRefeicaoOpen(false);

    const insertRefeicao = (event) => {
        event.preventDefault();
        if (document.querySelector("[name=nome]").value != "") {
            RefeicaoService.Insert({ nome: document.querySelector("[name=nome]").value }).then((response) => {
                document.querySelector("[name=nome]").value = "";
                fecharModalRefeicao();
                atualizarListaDietas();
            });
        } else {
            alert("Preencha o nome!");
        }
    }
    const deleteRefeicao = (id) => {
        if (confirm("Deseja realmente apagar a refeição?")) {
            RefeicaoService.Delete(id).then((response) => {
                atualizarListaDietas();
            });
        }
    }
    //modal refeicao

    const [dietaRefeicoes, setDietaRefeicoes] = React.useState(null);
    const [ref_alimentos, setRef_alimentos] = React.useState([]);

    React.useEffect(() => {
        atualizarListaDietas();
    }, []);

    const atualizarListaDietas = () => {
        DietaService.Todos().then((response) => {
            if (response) {
                setDietaRefeicoes(response);
            }
        });
        RefAlimentoService.Todos().then((response) => {
            if (response) {
                setRef_alimentos(response)
            }
        })
    }













    return (
        <div>






            <Modal
                open={modalRefeicaoOpen}
                onClose={fecharModalRefeicao}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Adicionar Refeição
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <form onSubmit={insertRefeicao}>
                            <input type='text' name="refeicaoid" />
                            <input type='text' name="nome" />
                            <input type='submit' value="Adicionar" />
                        </form>
                    </Typography>
                </Box>
            </Modal>







            <Modal
                open={modalAlimentoOpen}
                onClose={fecharModalAlimento}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Adicionar Alimento
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <form onSubmit={insertAlimento}>
                            <select name="alimento">
                                <option value="-1" name="alimento">Selecione o alimento</option>
                                {
                                    ref_alimentos.length > 0 ?
                                        ref_alimentos.map((e, key) => {
                                            return (
                                                <option data-alimentojson={JSON.stringify(e)} key={key} value={e.id}>{e.nome}</option>
                                            )
                                        })
                                        :
                                        null
                                }
                            </select>
                            <label>Gramas</label>
                            <input type='number' name="alimentoquantidade" />
                            <input type='submit' value="Adicionar" />
                        </form>
                    </Typography>
                </Box>
            </Modal>









            <Card style={{ margin: "10px" }}>
                <CardHeader title="Informações Pessoais" />
                <CardContent className='formulario-grupo'>

                    <div>




                        <h3>Dieta <button>Recalcular</button></h3>
                        {
                            dietaRefeicoes ?
                                <div>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        <div>{dietaRefeicoes.nome}</div>
                                        <div>Calorias: {dietaRefeicoes.calorias}</div>
                                        <div>Proteinas: {dietaRefeicoes.proteinas}</div>
                                        <div>Carboidratos: {dietaRefeicoes.carboidratos}</div>
                                        <div>Gordura: {dietaRefeicoes.gordura}</div>
                                    </div>
                                    <h3>Refeições <button onClick={abrirModalRefeicao}>+</button></h3>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                        {
                                            dietaRefeicoes.refeicoes.length > 0 ?
                                                dietaRefeicoes.refeicoes.map((e, key) => {
                                                    return (
                                                        <div key={key} style={{ border: "1px solid", padding: "10px" }}>
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div style={{ display: "flex", gap: "10px" }}><div style={{ fontWeight: "600" }}>{e.nome}</div> <div>Calorias: {e.calorias} Proteinas: {e.proteinas} Carboidratos: {e.carboidratos} Gordura: {e.gordura}</div></div>
                                                                <div><button onClick={() => { deleteRefeicao(e.id) }}>remover</button></div>
                                                            </div>

                                                            <h3>Alimentos <button onClick={() => { abrirModalAlimento(e); }}>+</button></h3>
                                                            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                                {
                                                                    e.alimentos.length > 0 ?
                                                                        e.alimentos.map((e2, key) => {
                                                                            return (

                                                                                <div key={key} style={{ display: "flex", justifyContent: "space-between", border: "1px solid", padding: "10px" }}>
                                                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                                                        <div style={{ fontWeight: "600" }}>{e2.nome}</div> <div>Calorias: {e2.calorias} Proteinas: {e2.proteinas} Carboidratos: {e2.carboidratos} Gordura: {e2.gordura} por {e2.gramas} gramas</div>
                                                                                        <b>Consumo: {e2.quantidade}</b>
                                                                                    </div>
                                                                                    <div><button onClick={() => { deleteAlimento(e2.id); }}>Remover</button></div>

                                                                                </div>)
                                                                        })

                                                                        :
                                                                        "não tem alimentos"
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                "não tem refeição"
                                        }


                                    </div>
                                </div>
                                :
                                "não tem "
                        }






                    </div>

                </CardContent>
            </Card>



        </div>
    );
}

