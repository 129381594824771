import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// TODO remove, this demo shouldn't need to reset the theme.

//campo senha
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//campo usuário
import AccountCircle from '@mui/icons-material/AccountCircle';
import FormHelperText from '@mui/material/FormHelperText';

import CardHeader from '@mui/material/CardHeader';

/*modal*/
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};
/*modal*/


import { useSelector, useDispatch } from 'react-redux'
import { addAlert } from '../../../Store/Shared/AlertSlice';
import { abrirLoader, fecharLoader } from '../../../Store/Shared/LoaderSlice';



//usuario service
import usuarioService from '../../../Service/Usuario/usuarioService';


const defaultTheme = createTheme();

export default function LoginPage() {

  const loader = useSelector((state) => state.LOADER.loader);
  const dispatch = useDispatch()

  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //modal

  //input password vizualizar
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //input password vizualizar

  const handleSubmit = (event) => {
    console.log("0");
    dispatch(abrirLoader());
    event.preventDefault();
    usuarioService.entrar({
      usuario: formulario.usuario.value,
      senha: formulario.senha.value,
    })
      .then((response) => {
        if (response.error) {
          dispatch(addAlert({
            id: new Date().getTime(),
            message: response.error,
            severity: 'error',
          }))
        } else {
          localStorage.setItem("token", response.token);
          location.href = location.href
        }
        dispatch(fecharLoader());
      })
      .catch((error) => {
        console.log("2");
        dispatch(addAlert({
          id: new Date().getTime(),
          message: error,
          severity: 'error',
        }))
        dispatch(fecharLoader());
      });
  };


  //campo usuário
  const [formulario, setFormulario] = React.useState({
    usuario: {
      value: '',
      error: false,
      messageErrors: [],
      validation: (event) => {
        console.log(event)
      }
    },
    senha: {
      value: '',
      error: false
    }
  })
  const handleFormulario = (event) => {
    if (event.type == "change") {
      let itemTemp = formulario[event.target.name];
      itemTemp.value = event.target.value;
      setFormulario({ ...formulario, [event.target.name]: itemTemp });
    }
  }
  //campo usuário








  return (
    <ThemeProvider theme={defaultTheme}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '10%',
        }}
      >














        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >





                <div style={{ display: "none" }}>
                  {JSON.stringify(formulario)}
                </div>




                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >










                  <FormControl variant="outlined" style={{ width: "100%", marginBottom: "20px" }}>
                    <InputLabel htmlFor="outlined-adornment">Usuário</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment"
                      type='text'
                      error={formulario.usuario.error}
                      name="usuario"
                      value={formulario.usuario.value}
                      onChange={handleFormulario}
                      onBlur={handleFormulario}
                      endAdornment={
                        <InputAdornment position="end">
                          <AccountCircle />
                        </InputAdornment>
                      }
                      label="Usuário"
                    />
                    {formulario.usuario.error && <FormHelperText error>Este campo é obrigatório</FormHelperText>}
                  </FormControl>


                  <FormControl variant="outlined"
                    style={{ width: "100%", marginBottom: "20px" }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Senha
                    </InputLabel>
                    <OutlinedInput
                      name="senha"
                      onChange={handleFormulario}
                      onBlur={handleFormulario}
                      value={formulario.senha.value}
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>








                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mb: 2 }}
                  >
                    Entrar
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2" onClick={handleOpen}>
                        Cadastrar
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2" onClick={handleOpen}>
                        Esqueceu a senha?
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </CardContent>
        </Card>
      </div>







      <div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>

            <Card >

              <CardHeader
                title="Recuperar Senha"
              />

              <CardContent>
                <Container component="main" maxWidth="xs">
                  <CssBaseline />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >




                    <p>Preencha seu usuário a após solicitar a redefinição aguarde, que você receberá um email para redefinir sua senha.</p>
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      noValidate
                      sx={{ mt: 1 }}
                    >




                      <FormControl variant="outlined" style={{ width: "100%", marginBottom: "20px" }}>
                        <InputLabel htmlFor="outlined-adornment">Usuário</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment"
                          type='text'
                          error={formulario.usuario.error}
                          name="usuario"
                          value={formulario.usuario.value}
                          onChange={handleFormulario}
                          onBlur={handleFormulario}
                          endAdornment={
                            <InputAdornment position="end">
                              <AccountCircle />
                            </InputAdornment>
                          }
                          label="Usuário"
                        />
                        {formulario.usuario.error && <FormHelperText error>Este campo é obrigatório</FormHelperText>}
                      </FormControl>



                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mb: 2 }}
                      >
                        Enviar
                      </Button>

                    </Box>
                  </Box>
                </Container>
              </CardContent>
            </Card>
          </Box>
        </Modal>
      </div>




    </ThemeProvider>
  );
}
