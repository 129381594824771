import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import AcompanhamentoService from '../../../../Service/Usuario/AcompanhamentoService';
import AtividadeService from '../../../../Service/Usuario/AtividadeService';


import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function InformacoesPage() {
    const [ultimoAcompanhamento, setUltimoAcompanhamento] = React.useState(null);
    const [ultimaAtividade, setUltimaAtividade] = React.useState(null);

    React.useEffect(() => {
        AcompanhamentoService.GetLast()
            .then((response) => {
                if (response) {
                    setUltimoAcompanhamento(response);
                }
            });
        AtividadeService.GetLast().then((response) => {
            if (response) {
                setUltimaAtividade(response);
            }
        });

    }, []);

    return (
        <div>





            <Card style={{ margin: "10px" }}>
                <CardHeader title="GUIA" />
                <CardContent className='formulario-grupo'>






                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            IMC
                        </AccordionSummary>
                        <AccordionDetails>
                            <ol>
                                <li>
                                    
                                </li>
                               
                            </ol>

        
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            TMB
                        </AccordionSummary>
                        <AccordionDetails>
                        <h3> Taxa de Metabolica Basal BRASIL (TMB), EUA (MBR)</h3>
                                    <p>Quantidade necessária de calorias para seu organismo se manter diariamente</p>
                                    {
                                        ultimoAcompanhamento != null ?
                                            <>
                                                <ul>
                                                    <li><label>Homem:</label> tmb = 66.5 + (13.75 * peso em kilogramas) + (5.003 * altura em centimetros) - (6.755 * idade)</li>
                                                    <li><label>Mulher:</label> tmb = 66.5 + (9.6 * peso em kilogramas) + (1.8 * altura em centimetros) - (4.7 * idade)</li>
                                                </ul>
                                                <div>
                                                    <h3>Ultimo Acompanhamento Cadastrado</h3>
                                                    <div><label>Altura: </label>{ultimoAcompanhamento.altura}</div>
                                                    <div><label>Peso: </label>{ultimoAcompanhamento.peso}</div>
                                                    <div><label>Idade: </label>{ultimoAcompanhamento.idade}</div>
                                                    <div><label>Data: </label>{ultimoAcompanhamento.data_cadastro}</div>
                                                </div>

                                                <h1>TMB {ultimoAcompanhamento.tmb}</h1>
                                            </>
                                            :
                                            null
                                    }
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel2-header"
                        >
                            TDE
                        </AccordionSummary>
                        <AccordionDetails>
                        <h2>TDEE</h2>
                                    <p>Gasto diário de energia.</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>coeficiente</th>
                                                <th>atividade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1,2</td>
                                                <td>Pessoa sedentária</td>
                                            </tr>
                                            <tr>
                                                <td>1,5</td>
                                                <td>Pessoas levemente ativas (exercícios leves em 1 a 3 vezes por semana)</td>
                                            </tr>
                                            <tr>
                                                <td>1,725</td>
                                                <td>Pessoas muito ativas (exercícios intensos em 6 a 7 dias por semana)</td>
                                            </tr>
                                            <tr>
                                                <td>1,9</td>
                                                <td>Pessoas extremamente ativas (exercícios intensos diariamente, ou mais de um treino por dia)</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h3>Ultima atualização de Rotina Cadastrada</h3>
                                    {
                                        ultimaAtividade ?
                                            <div>

                                                <div><label>Nome: </label>{ultimaAtividade.nome}</div>
                                                <div><label>Descrição: </label>{ultimaAtividade.descricao}</div>
                                                <div><label>Data: </label>{ultimaAtividade.data_cadastro}</div>
                                            </div>
                                            :
                                            <p>Nenhuma cadastrada</p>
                                    }
                                    <h1>{ultimaAtividade ? "TDEE " + ultimaAtividade.valor : null}</h1>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4-content"
                            id="panel2-header"
                        >
                            Macro Nutrientes
                        </AccordionSummary>
                        <AccordionDetails>
                        <p>Podemos definir que carboidratos, proteinas, gorduras e alcool tem uma pequena calorias confira abaixo</p>
                    <table>
                        <thead>
                            <tr>
                                <th>Gramas</th>
                                <th>nome</th>
                                <th>kcal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>proteina</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>carboidratos</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>gordura</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>alcool</td>
                                <td>7</td>
                            </tr>
                        </tbody>
                    </table>
                        </AccordionDetails>
                    </Accordion>














                </CardContent>
            </Card>


        </div>
    );
}

