// store.js
import { createSlice } from '@reduxjs/toolkit'

export const AlertSlice = createSlice({
    name: 'alerts',
    initialState: { alerts: [] },
    reducers: {
      addAlert: (state, action) => {
        state.alerts.push(action.payload);
      },
      removeAlert: (state, action) => {
        state.alerts = state.alerts.filter((alert) => alert.id !== action.payload);
      },
    },
})

export const { addAlert, removeAlert } = AlertSlice.actions

export default AlertSlice.reducer