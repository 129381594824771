// Alert.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { abrirLoader, fecharLoader } from '../../Store/Shared/LoaderSlice';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LoaderComponent = () => {
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.LOADER.loader);


    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        dispatch(fecharLoader());
        //setOpen(false);
    };

    const handleAgree = () => {
        // your custom function here
        handleClose();
    };


    return (




        <Dialog
            open={loader.on}
            disableBackdropClick={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <DialogTitle>Processando</DialogTitle>
                <DialogContentText id="alert-dialog-description">
                    Aguarde Carregando...
                </DialogContentText>
            </DialogContent>
            <Box sx={{ display: 'flex' }} style={{padding: "0px 0px 20px 0px", alignSelf: "center"}}>
                <CircularProgress />
            </Box>
            <DialogActions style={{ display: "none" }}>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleAgree} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>


    );

};
export default LoaderComponent;
