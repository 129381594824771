// Alert.js
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlert } from '../../Store/Shared/AlertSlice';


const AlertComponent = () => {
    const dispatch = useDispatch();
    const alerts = useSelector((state) => state.ALERT.alerts);

    const handleClose = (id, event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(removeAlert(id));
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'fixed', top: 0, right: 0, zIndex: 9999, overflow: "auto", height: "100vh" }}>
            {alerts.map((alert) => (
                <Snackbar
                    style={{ position: 'unset', margin: "5px" }}
                    key={alert.id}
                    open={true}
                    autoHideDuration={5000}
                    onClose={(event, reason) => handleClose(alert.id, event, reason)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={(event, reason) => handleClose(alert.id, event, reason)}
                        severity={alert.severity}
                    >
                        {alert.message}
                    </MuiAlert>
                </Snackbar>
            ))}
        </div>
    );
};
export default AlertComponent;
