import * as React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import CloudUploadIcon from '@mui/icons-material/Cloud';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { useSelector, useDispatch } from 'react-redux'
import { addAlert } from '../../../../../Store/Shared/AlertSlice';
import { abrirLoader, fecharLoader } from '../../../../../Store/Shared/LoaderSlice';

import InformacaoPessoalService from '../../../../../Service/InformacaoPessoal/InformacaoPessoalService';
import AcompanhamentoService from '../../../../../Service/Usuario/AcompanhamentoService';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function InformacaoPessoalComponente({ fecharModalAcompanhamento }) {

    const loader = useSelector((state) => state.LOADER.loader);
    const dispatch = useDispatch()

    const [utilmoAcData, setutilmoAcData] = React.useState("");
    const [atendimento, setAtendimento] = React.useState({
        altura: {
            value: ""
        },
        peso: {
            value: ""
        }
    });

    React.useEffect(() => {
        AcompanhamentoService.GetLast()
            .then((response) => {
                if (response) {
                    setutilmoAcData(response.data_cadastro)
                    setAtendimento({
                        altura: {
                            value: response.altura
                        },
                        peso: {
                            value: response.peso
                        }
                    })
                }
            })
    }, [])

    const handleFormulario = (event) => {
        if (event.type == "change") {
            let itemTemp = atendimento[event.target.name];
            itemTemp.value = event.target.value;
            setAtendimento({ ...atendimento, [event.target.name]: itemTemp });
        }
    }

    const [loading, setLoading] = React.useState(false);
    function handleClick() {
        setLoading(true);

    }











    const [images, setImages] = React.useState([]);

    const handleImageUpload = event => {
        let file = event.target.files[0];
        let reader = new FileReader();

        reader.onloadend = () => {
            setImages(oldImages => [
                ...oldImages,
                { id: null, nome: file.name, binario: reader.result }
            ]);
        };

        reader.readAsDataURL(file);

        // Limpa o valor do input
        event.target.value = null;
    };

    const handleImageRemove = index => {
        setImages(oldImages => oldImages.filter((img, i) => i !== index));
    };








    const salvar = () => {
        dispatch(abrirLoader());
        //event.preventDefault();
        AcompanhamentoService.Insert({
            altura: atendimento.altura.value,
            peso: atendimento.peso.value,
            imagens: images
        })
            .then((response) => {
                dispatch(addAlert({
                    id: new Date().getTime(),
                    message: 'Salvo!',
                    severity: 'success',
                }))
                dispatch(fecharLoader());
                fecharModalAcompanhamento()
            })
            .catch((error) => {
                console.log("2");
                dispatch(addAlert({
                    id: new Date().getTime(),
                    message: error,
                    severity: 'error',
                }))
                dispatch(fecharLoader());
            });
    }


    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>



            <Grid container spacing={2}>
                {
                    utilmoAcData != "" ?
                        <div style={{
                            textAlign: "center",
                            fontSize: "12px",
                            width: "100%",
                            marginTop: "10px",
                            color: "#757575"
                        }}>Ultimo acompanhamento registrado {utilmoAcData}</div>
                        :
                        null
                }

                <Grid item xs={12} md={6}>
                    <TextField id="outlined-basic" size="small" label="ALTURA" variant="outlined" fullWidth name="altura" value={atendimento.altura.value} onChange={handleFormulario} onBlur={handleFormulario} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField id="outlined-basic" size="small" label="PESO KG" variant="outlined" fullWidth name="peso" value={atendimento.peso.value} onChange={handleFormulario} onBlur={handleFormulario} />
                </Grid>
            </Grid>






            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                Adicionar Imagem
                <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
            </Button>
            {
                images.length > 0 ?

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Imagem</TableCell>
                                    <TableCell>Ação</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {images.map((image, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{image.nome}</TableCell>
                                        <TableCell><img src={image.binario} alt={image.nome} width="100" /></TableCell>
                                        <TableCell><button onClick={() => handleImageRemove(index)}>Remover</button></TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    null
            }








            <div style={{ display: "flex", justifyContent: "right", paddingBottom: "0px" }}>
                <LoadingButton
                    size="small"
                    onClick={salvar}
                    endIcon={<SendIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                >
                    <span>Enviar</span>
                </LoadingButton>
            </div>
        </div>
    );
}
