import axios from '../axiosConfig';

const AlimentoService = {
    Insert: async (data) => {
        try {
            const response = await axios.post('/api/alimento', data);
            return response.data;
        } catch (error) {
            console.error('Erro ao cadastrar usuário:', error);
            throw error;
        }
    },
    Delete: async (id) => {
        try {
            const response = await axios.delete('/api/alimento?id=' + id);
            return response.data;
        } catch (error) {
            console.error('Erro ao cadastrar usuário:', error);
            throw error;
        }
    },
};

export default AlimentoService;
