// axiosConfig.js
const baseURL = 'https://dieta.julianodev.com.br';
const timeout = 5000;

const sendRequest = (method, url, data = null) => {
  
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, baseURL + url, true);
    xhr.timeout = timeout;

    // Adicione outros cabeçalhos conforme necessário
    xhr.setRequestHeader('Content-Type', 'application/json');

    const token = localStorage.getItem('token');
    if (token) {
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      //xhr.withCredentials = true;
    }
    //xhr.withCredentials = true;
    // Adiciona suporte a requisições cross-domain
    //xhr.crossDomain = true;

    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        reject(new Error(`Erro na requisição. Status: ${xhr.status}`));
      }
    };

    xhr.onerror = function () {
      reject(new Error('Erro de rede ao fazer a requisição.'));
    };

    xhr.ontimeout = function () {
      reject(new Error('Tempo limite da requisição excedido.'));
    };

    xhr.send(data ? JSON.stringify(data) : null);
  });
};

const renewToken = async () => {
  // Lógica para renovar o token, por exemplo, fazendo uma requisição para um endpoint dedicado
  // Retorna o novo token
};

const interceptRenewToken = async (error) => {
  if (error.status === 401) {
    // Renova o token (implementação depende da sua lógica de renovação)
    const novoToken = await renewToken();

    // Refaz a requisição original com o novo token
    return sendRequest(error.method, error.url, error.data);
  }
  // Se não for um erro de token expirado, retorna o erro
  throw error;
};

export const get = (url) => sendRequest('GET', url);
export const getWithData = (url) => sendRequest('GET', url, data);
export const post = (url, data) => sendRequest('POST', url, data);
export const put = (url, data) => sendRequest('PUT', url, data);
export const del = (url) => sendRequest('DELETE', url);

export const interceptors = {
  response: (onSuccess, onError) => ({ onSuccess, onError }),
};

export const initInterceptors = () => {
  const responseInterceptor = interceptors.response(
    (response) => response,
    (error) => interceptRenewToken(error)
  );

  return {
    response: {
      use: (onSuccess, onError) => {
        responseInterceptor.onSuccess = onSuccess || responseInterceptor.onSuccess;
        responseInterceptor.onError = onError || responseInterceptor.onError;
      },
    },
  };
};

const axiosInstance = {
  ...interceptors,
  initInterceptors,
  get,
  getWithData,
  post,
  put,
  delete: del,
};

export default axiosInstance;
