import { createSlice } from '@reduxjs/toolkit'

export const LoaderSlice = createSlice({
    name: 'loader',
    initialState: { loader: { on: false, percent: 0 } },
    reducers: {
        abrirLoader: (state) => {
            state.loader.on = true
        },
        fecharLoader: (state) => {
            state.loader.on = false
        },
        setPercent: (state, action) => {
            state.loader.percent = action.payload
        },
    },
})

export const { abrirLoader, fecharLoader, setPercent } = LoaderSlice.actions

export default LoaderSlice.reducer
