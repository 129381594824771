import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import InformacaoPessoalComponente from './InformacaoPessoal/InformacaoPessoalComponente'

import usuarioService from '../../../../Service/Usuario/usuarioService';
import AcompanhamentoService from '../../../../Service/Usuario/AcompanhamentoService';
import AtividadeService from '../../../../Service/Usuario/AtividadeService';
import DietaService from '../../../../Service/Usuario/DietaService';

//modal
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
//modal
















export default function AtendimentoPage() {

  //modal acompanhamento
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const fecharModalAcompanhamento = () => {
    console.log("fechou")
    setOpen(false);
  }
  //modal acompanhamento

  const [usuario, setUsuario] = React.useState({
    nome: "",
    usuario: "",
    data_nascimento: "",
    sexo: "",
    idade: ""
  })
  const [ultimoAcompanhamento, setUltimoAcompanhamento] = React.useState(null);
  const [ultimaAtividade, setUltimaAtividade] = React.useState(null);
  const [dietaRefeicoes, setDietaRefeicoes] = React.useState(null);








  React.useEffect(() => {
    //usuarioService.getUsuarioByToken
    usuarioService.getUsuarioByToken()
      .then((response) => {
        if (response) {
          setUsuario({
            nome: response.nome,
            usuario: response.usuario,
            //data_nascimento: response.data_nascimento.split(" ").split("-").reverse().join("/"),
            data_nascimento: response.data_nascimento,
            sexo: response.sexo == 1 ? "Homem" : "Mulher",
            idade: calcularIdade(response.data_nascimento)
          })
        }
      });
    /*
          InformacaoPessoalService.GetLast()
          .then((response) => {
            if (response.cod == 200) {
              setUltimoAcompanhamento(response.data);
            }
          })
          */
    AcompanhamentoService.GetLast()
      .then((response) => {
        if (response) {
          setUltimoAcompanhamento(response);
        }
      });
    AtividadeService.GetLast().then((response) => {
      if (response) {
        setUltimaAtividade(response);
      }
    });
    DietaService.Todos().then((response) => {
      if (response) {
        setDietaRefeicoes(response);
      }
    });
  }, []);


  //utils
  const calcularIdade = (dataString) => {
    const dataNascimento = new Date(dataString);
    const dataAtual = new Date();
    const diferencaMilissegundos = dataAtual - dataNascimento;
    return Math.floor(diferencaMilissegundos / (365.25 * 24 * 60 * 60 * 1000));
  }









  const [proteina, setProteina] = React.useState(30);
  const [carboidrato, setCarboidrato] = React.useState(50);
  const [gordura, setGordura] = React.useState(20);

  React.useEffect(() => {
    total = proteina + carboidrato + gordura;

    toRadians = angle => angle * (Math.PI / 180);
  }, [proteina, carboidrato, gordura]);

  var total = proteina + carboidrato + gordura;

  var toRadians = angle => angle * (Math.PI / 180);

  const createArc = (startAngle, endAngle, color) => {
    const x1 = 100 + 100 * Math.cos(toRadians(startAngle));
    const y1 = 100 + 100 * Math.sin(toRadians(startAngle));
    const x2 = 100 + 100 * Math.cos(toRadians(endAngle));
    const y2 = 100 + 100 * Math.sin(toRadians(endAngle));

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
      `M 100 100`,
      `L ${x1} ${y1}`,
      `A 100 100 0 ${largeArcFlag} 1 ${x2} ${y2}`,
      `L 100 100`
    ].join(' ');

    return <path d={d} fill={color} />;
  };









  return (
    <div>






      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Adicionar Acompanhamento
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <InformacaoPessoalComponente fecharModalAcompanhamento={fecharModalAcompanhamento} />
          </Typography>
        </Box>
      </Modal>












      <Card style={{ margin: "10px" }}>
        <CardHeader title="Informações Pessoais" />
        <CardContent className='formulario-grupo'>

          <div>
            <ul>
              <li><label>Usuário: </label>{usuario.usuario}</li>
              <li><label>Nome: </label>{usuario.nome}</li>
              <li><label>Data de Nascimento: </label>{usuario.data_nascimento}</li>
              <li><label>Idade: </label>{usuario.idade} anos</li>
              <li><label>Sexo: </label>{usuario.sexo}</li>
            </ul>

            <div>
              <svg width="200" height="200">
                {createArc(0, (proteina / total) * 360, '#FF6384')}
                {createArc((proteina / total) * 360, ((proteina + carboidrato) / total) * 360, '#36A2EB')}
                {createArc(((proteina + carboidrato) / total) * 360, 360, '#FFCE56')}
              </svg>
              <div>
                <div><label>Carboidratos</label><input type='number' value={carboidrato} onChange={(event) => { console.log(event.target.value); setCarboidrato(event.target.value) }} /></div>
                <div><label>Proteina</label><input type='number' value={proteina} onChange={(event) => { setProteina(event.target.value) }} /></div>
                <div><label>Gordura</label><input type='number' value={gordura} onChange={(event) => { setGordura(event.target.value) }} /></div>
              </div>
            </div>


            <h3>Dieta</h3>
            {
              dietaRefeicoes ?
                <div>
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <div>{dietaRefeicoes.nome}</div>
                    <div>Calorias: {dietaRefeicoes.calorias}</div>
                    <div>Proteinas: {dietaRefeicoes.proteinas}</div>
                    <div>Carboidratos: {dietaRefeicoes.carboidratos}</div>
                    <div>Gordura: {dietaRefeicoes.gordura}</div>
                  </div>
                  <h3>Refeições <button>+</button></h3>
                  <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    {
                      dietaRefeicoes.refeicoes.length > 0 ?
                        dietaRefeicoes.refeicoes.map((e) => {
                          return (
                            <div style={{ border: "1px solid", padding: "10px" }}>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>{e.nome}</div>
                                <div><button>remover</button></div>
                              </div>
                              <h3>Alimentos <button>+</button></h3>
                              {
                                e.alimentos.length > 0 ?
                                  e.alimentos.map((e2) => {
                                    return (
                                      <div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                          <div>{e2.nome} <div>Calorias: {e2.calorias} Proteinas: {e2.proteinas} Carboidratos: {e2.carboidratos} Gordura: {e2.gordura}</div></div>
                                          <div><button>Remover</button></div>
                                        </div>
                                      </div>)
                                  })

                                  :
                                  "não tem alimentos"
                              }
                            </div>
                          )
                        })
                        :
                        "não tem refeição"
                    }


                  </div>
                </div>
                :
                "não tem "
            }






          </div>

        </CardContent>
      </Card>


      <Card style={{ margin: "10px" }}>
        <CardHeader title="GUIA" />
        <CardContent className='formulario-grupo'>

          <ol>
            <li>
              <h3> Taxa de Metabolica Basal BRASIL (TMB), EUA (MBR)</h3>
              <p>Quantidade necessária de calorias para seu organismo se manter diariamente</p>
              {
                ultimoAcompanhamento != null ?
                  <>
                    <ul>
                      <li><label>Homem:</label> tmb = 66.5 + (13.75 * peso em kilogramas) + (5.003 * altura em centimetros) - (6.755 * idade)</li>
                      <li><label>Mulher:</label> tmb = 66.5 + (9.6 * peso em kilogramas) + (1.8 * altura em centimetros) - (4.7 * idade)</li>
                    </ul>
                    <div>
                      <h3>Ultimo Acompanhamento Cadastrado</h3>
                      <div><label>Altura: </label>{ultimoAcompanhamento.altura}</div>
                      <div><label>Peso: </label>{ultimoAcompanhamento.peso}</div>
                      <div><label>Idade: </label>{ultimoAcompanhamento.idade}</div>
                      <div><label>Data: </label>{ultimoAcompanhamento.data_cadastro}</div>
                    </div>

                    <h1>TMB {ultimoAcompanhamento.tmb}</h1>
                  </>
                  :
                  null
              }
            </li>
            <li>
              <h2>TDEE</h2>
              <p>Gasto diário de energia.</p>
              <table>
                <thead>
                  <tr>
                    <th>coeficiente</th>
                    <th>atividade</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1,2</td>
                    <td>Pessoa sedentária</td>
                  </tr>
                  <tr>
                    <td>1,5</td>
                    <td>Pessoas levemente ativas (exercícios leves em 1 a 3 vezes por semana)</td>
                  </tr>
                  <tr>
                    <td>1,725</td>
                    <td>Pessoas muito ativas (exercícios intensos em 6 a 7 dias por semana)</td>
                  </tr>
                  <tr>
                    <td>1,9</td>
                    <td>Pessoas extremamente ativas (exercícios intensos diariamente, ou mais de um treino por dia)</td>
                  </tr>
                </tbody>
              </table>
              <h3>Ultima atualização de Rotina Cadastrada</h3>
              {
                ultimaAtividade ?
                  <div>

                    <div><label>Nome: </label>{ultimaAtividade.nome}</div>
                    <div><label>Descrição: </label>{ultimaAtividade.descricao}</div>
                    <div><label>Data: </label>{ultimaAtividade.data_cadastro}</div>
                  </div>
                  :
                  <p>Nenhuma cadastrada</p>
              }
              <h1>{ultimaAtividade ? "TDEE " + ultimaAtividade.valor : null}</h1>
            </li>
          </ol>




          {
            ultimoAcompanhamento != null ?
              <h1>TMB {ultimoAcompanhamento.tmb}   {ultimaAtividade ? " X TDEE " + ultimaAtividade.valor + " = " + (ultimoAcompanhamento.tmb * ultimaAtividade.valor) : null}</h1>
              :
              null
          }
        </CardContent>
      </Card>

      <Card style={{ margin: "10px" }}>
        <CardHeader title="Macro nutrientes" />
        <CardContent className='formulario-grupo'>

          <p>Podemos definir que carboidratos, proteinas, gorduras e alcool tem uma pequena calorias confira abaixo</p>
          <table>
            <thead>
              <tr>
                <th>Gramas</th>
                <th>nome</th>
                <th>kcal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>proteina</td>
                <td>4</td>
              </tr>
              <tr>
                <td>1</td>
                <td>carboidratos</td>
                <td>4</td>
              </tr>
              <tr>
                <td>1</td>
                <td>gordura</td>
                <td>9</td>
              </tr>
              <tr>
                <td>1</td>
                <td>alcool</td>
                <td>7</td>
              </tr>
            </tbody>
          </table>


        </CardContent>
      </Card>




      <div style={{
        position: "fixed",
        bottom: "0px",
        right: "0px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "10px"
      }}>

        <Fab color="secondary" aria-label="add" onClick={handleOpen}>
          <AddIcon />
        </Fab>
      </div>


    </div>
  );
}

