// usuarioService.js
import axios from '../axiosConfig';

const AcompanhamentoService = {
  GetLast: async () => {
    try {
      const response = await axios.get('/api/acompanhamento/lastbytoken');
      return response.data;
    } catch (error) {
      console.error('Erro ao cadastrar usuário:', error);
      throw error;
    }
  },
  Insert: async (data) => {
    try {
      const response = await axios.post('/api/acompanhamento', data);
      return response.data;
    } catch (error) {
      console.error('Erro ao cadastrar usuário:', error);
      throw error;
    }
  },
  delete: async (id) => {
    try {
      const response = await axios.delete('/api/acompanhamento?id=' + id);
      return response.data;
    } catch (error) {
      console.error('Erro ao cadastrar usuário:', error);
      throw error;
    }
  },
  buscarPorPeriodo: async () => {
    try {
      const response = await axios.get('/api/acompanhamento/getbyperiod');
      return response.data;
    } catch (error) {
      console.error('Erro ao cadastrar usuário:', error);
      throw error;
    }
  }
};

export default AcompanhamentoService;
