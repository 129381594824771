import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

//modal
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
//modal

import Box from '@mui/material/Box';
import AcompanhamentoService from '../../../../Service/Usuario/AcompanhamentoService';

export default function AcompanhamentoPage() {

    const [filtros, setFiltros] = React.useState({
        data_inicio: "",
        data_termino: ""
    });

    const [acompanhamentos, setAcompanhamentos] = React.useState([]);

    React.useEffect(() => {
        atualizarTabela();
    }, []);

    const atualizarTabela = () => {
        AcompanhamentoService.buscarPorPeriodo()
            .then((response) => {
                if (response) {
                    console.log(response)
                    setAcompanhamentos(response);
                }
            })
    }

    const [modalImagem, setModalImagem] = React.useState(false)
    const [modalImagens, setModalImagens] = React.useState([])
    const abrirModalImagem = (imagens) => {
        setModalImagens(imagens)
        setModalImagem(true)
    }
    const fecharModalImagem = () => {
        setModalImagem(false)
    }


    const removerAcompanhamento = (id) => {
        let valid = confirm("deseja relmanete remover?");
        if (valid) {
            AcompanhamentoService.delete(id)
                .then((response) => {
                    atualizarTabela();
                })
            alert("removido")
        }
    }
    return (
        <Card style={{ margin: "10px" }}>
            <CardHeader title="Acompanhamentos" />
            <CardContent className='formulario-grupo'>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>data</th>
                                <th>altura</th>
                                <th>peso</th>
                                <th>TMB</th>
                                <th>Imagens</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                acompanhamentos.length > 0?
                                acompanhamentos.map((e, key) => {
                                    return <tr key={key}>
                                        <td>{e.data_cadastro}</td>
                                        <td>{e.altura}</td>
                                        <td>{e.peso}</td>
                                        <td>{e.tmb}</td>
                                        {
                                            e.imagens.length > 0 ?
                                                <td><button onClick={() => { abrirModalImagem(e.imagens) }}>{e.imagens.length}</button></td>
                                                :
                                                <td>{e.imagens.length}</td>
                                        }
                                        <td><button onClick={() => { removerAcompanhamento(e.id); }}>Remover</button></td>
                                    </tr>
                                })
                                : 
                                <tr><td>não tem</td></tr>
                            }
                        </tbody>
                    </table>
                </div>





                <Modal
                    open={modalImagem}
                    onClose={fecharModalImagem}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Imagens
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                            {
                                modalImagens.map((e) => {
                                    return (
                                        <div>
                                            <img style={{ width: "100%" }} src={e.nome} />
                                        </div>
                                    )
                                })
                            }


                        </Typography>
                    </Box>
                </Modal>





            </CardContent>
        </Card>
    );
}
