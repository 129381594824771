import axios from '../axiosConfig';

const DietaService = {
    Todos: async () => {
        try {
            const response = await axios.get('/api/dieta');
            return response.data;
        } catch (error) {
            console.error('Erro ao cadastrar usuário:', error);
            throw error;
        }
    },
};

export default DietaService;
